import { asyncReadable, asyncDerived, get, asyncWritable } from "@square/svelte-store";
import { collection, query, where, getDocs , orderBy, doc, getDoc, onSnapshot, QuerySnapshot } from 'firebase/firestore';
import { db } from '$lib/firebase';
import { auth } from '$lib/auth';
import { querystring } from "@firebase/util";

const season = asyncReadable(undefined, async () => {

    const collSeasons = collection(db, "seasons");
    var holdSeason = '';
    const q = query(collSeasons, where("isActive", "==", true));
    try {
        const querySnapshot = await getDocs (q);
        querySnapshot.forEach((doc) => {
            holdSeason = doc.id;
        });
    } catch(err) {
        console.error(`Error retrieving active season: ${err}`);
    }

    //console.log(`setting the season store as ${holdSeason}`);
    return holdSeason; 

});

const seasonInfo = asyncDerived([season, auth], async ([$season, $auth]) => {
    var tempSeasonInfo = {};
    if ($auth.user) {
        const docRef = doc(db, `seasons`, $season);
        try {
            var docSnap = await getDoc(docRef);
        } catch(err) {
            console.error(`error getting seasonInfo store:`);
            console.dir(err);
            return tempSeasonInfo;
        }

        if (docSnap.exists()){
            tempSeasonInfo = docSnap.data();
        } else {
            console.log(`No such season info exists`);
        }
    }
    return tempSeasonInfo;
    },
    true        
);

const userInfo = asyncDerived(auth, async ($auth) => {
    var tempUserInfo = {};
    if ($auth.user){
        const docRef = doc(db, `users`, $auth.user.uid);
        try {
            var docSnap = await getDoc(docRef);
        } catch(err) {
            console.error(`error getting userInfo store:`);
            console.dir(err);
            return tempUserInfo;
        }

        if (docSnap.exists()){
            tempUserInfo = docSnap.data();
        } else {
            console.log(`No such user info exists`);
        }
    }
    return tempUserInfo;
    },
    true
);

const weeks = asyncDerived([auth, season], async ([$auth, $season]) => {
    const tempWeeks = [];
    const collWeeks = collection(db, `seasons/${$season}/weeks`);
    const q = query(collWeeks, orderBy("minGameDate", "asc"));
    if ($auth.user){
        try {
            const querySnapshot = await getDocs(q);
            var holdIsActiveIdx = -1;
            querySnapshot.forEach((doc)=>{
                tempWeeks.push({id: doc.id, ...doc.data()});
                //console.log('just pushed a week');
                if(!doc.data().isPostWeekProcessed && holdIsActiveIdx === -1) {
                    tempWeeks[tempWeeks.length-1].active = true;
                    holdIsActiveIdx = 1;
                }
            });        
        } catch(err) {
            console.error(`Error retrieving weeks: ${err}`);
        }
    }

    return tempWeeks;
}, true);

const entries = asyncDerived([auth, season],
    async ([$auth, $season]) => {
        //console.log(`making entries call for: ${$auth}`);
        const tempEntries = [];
        const collAccounts = collection(db, `users/${$auth.user.uid}/seasons/${$season}/accounts`);
        const q = query(collAccounts, orderBy("createdAt", "asc"));
        
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            tempEntries.push({id: doc.id, ...doc.data()});
        });
        /*
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            querySnapshot.forEach((doc) => {
                tempEntries.push({id: doc.id, ...doc.data()});
            })
            return tempEntries;
        });
        */
        
        return tempEntries;
    },
    true
);

const pickWeeks = asyncDerived([auth, season, entries],
    async ([$auth, $season, $entries])=>{
        const tempPicks = [];
        for (const [indexAccount, account] of $entries.entries()) {
            //console.log(`looping in picks for ${account.id}`)
            const collPicks = collection(db, `users/${$auth.user.uid}/seasons/${$season}/accounts/${account.id}/pickWeeks`);
            const q = query(collPicks, orderBy("game.kickOffTime", "asc"));
            const querySnapshot = await getDocs(q);
            //console.log(`size of snapshot for ${account.id}: ${querySnapshot.size}`);
            querySnapshot.forEach((doc) => {
                //console.log(`pushing pick for: ${account.id}`);
                tempPicks.push(
                    {
                        id: doc.id,
                        accountId: account.id,
                        ... doc.data()
                    }
                )
            });
        }
        
        return tempPicks;
    },
    true
);

const activeIndex = asyncDerived(weeks, ($weeks) => {
    var holdIsActiveIdx = -1;
    $weeks.forEach((doc, idxDoc) => {
        if(!doc.isPostWeekProcessed && holdIsActiveIdx === -1) {
            holdIsActiveIdx = idxDoc;
        }
    });
    return holdIsActiveIdx;
});

export { season, seasonInfo, weeks, activeIndex, entries, pickWeeks, userInfo };